@import 'base';

@font-face {
  font-family: 'Nunito Sans';
  src: url('~typeface-nunito-sans/files/nunito-sans-latin-400.woff2');
}

/* Scroll configuration - For webkit browsers
--------------------------------------------------- */
::-webkit-scrollbar {
  width: 12px;
  height: 7px;
}
::-webkit-scrollbar-track {
  background-color: rgba($primary, 0.5);
  border-radius: 0;
}
::-webkit-scrollbar-thumb {
  background-color: rgba($primary, 0.9);
  border-radius: 3px;
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba($primary, 0.3);
}
/* Base styles
--------------------------------------------------- */
* {
  user-select: none;
  font-family: 'Nunito Sans', serif;
}
html,
body {
  width: 100%;
  min-height: calc(100vh - #{$navbar-height}) !important;
  font-size: 18px;
  background: rgba(255, 255, 255, 1);
  background: -moz-linear-gradient(
    45deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 27%,
    rgba(245, 245, 245, 1) 65%,
    rgba(247, 247, 247, 1) 100%
  );
  //noinspection CssInvalidFunction
  background: -webkit-gradient(
    left bottom,
    right top,
    color-stop(0%, rgba(255, 255, 255, 1)),
    color-stop(27%, rgba(246, 246, 246, 1)),
    color-stop(65%, rgba(245, 245, 245, 1)),
    color-stop(100%, rgba(247, 247, 247, 1))
  );
  background: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 27%,
    rgba(245, 245, 245, 1) 65%,
    rgba(247, 247, 247, 1) 100%
  );
  background: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 27%,
    rgba(245, 245, 245, 1) 65%,
    rgba(247, 247, 247, 1) 100%
  );
  background: -ms-linear-gradient(
    45deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 27%,
    rgba(245, 245, 245, 1) 65%,
    rgba(247, 247, 247, 1) 100%
  );
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 27%,
    rgba(245, 245, 245, 1) 65%,
    rgba(247, 247, 247, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f7f7f7', GradientType=1 );

  #app {
    min-height: 100%;
    height: auto !important;
  }
}

.vs-component.vs-con-textarea.input-icon-validate-danger.vs-textarea-primary {
  border: 1px solid rgba(var(--vs-danger), 1) !important;
}
.full-height {
  min-height: calc(100vh - #{$navbar-height} - #{$footer-height}) !important;
}

.overflow-auto {
  overflow: auto;
}

.con-vs-tabs .con-slot-tabs {
  margin-top: 3em;
}

// Fix over input labels
.vs-con-input-label {
  width: 100% !important;
}
.small-font {
  font-size: 0.9em;
}
.small-danger {
  color: red;
  display: block;
  margin-top: -15px;
}

.text-green {
  color: green;
}
.text-red {
  color: red;
}

.display-none {
  display: none !important;
}

.vuesax-app-is-ltr .vs-input--input.large.hasValue + .vs-placeholder-label,
.vuesax-app-is-ltr .vs-input--input.large:focus + .vs-placeholder-label {
  transform: translate(-3px, -75%) !important;
}

.con-ul-tabs {
  position: fixed !important;
  left: 0;
  right: 0;
  z-index: 10000;
  background-color: rgba(253, 253, 253, 0.85);
}

.fab-main-container {
  right: 5rem !important;
  bottom: 5rem !important;
}
.fab-size-big {
  height: 56px !important;
  width: 56px !important;
}

.vs-input--placeholder.large {
  padding-top: 0.9rem !important;
}

.col-inputs-full > span {
  width: 100%;
}
.con-select,
.input-select-con {
  width: 100% !important;
}

.footer-card {
  position: relative;
  padding-top: 15px;
  transform: translateY(50%);
  margin-top: -5%;
}

.text-bold {
  font-weight: 600;
}

.flex-column {
  flex-direction: column;
}
.justify-content-end {
  display: flex;
  justify-content: flex-end;
}
.justify-content-center {
  display: flex;
  justify-content: center;
}

.justify-content-left {
  display: flex;
  justify-content: left;
}

.full-width {
  width: 100% !important;
}

.text-muted {
  color: #6c757d !important;
}

.rating-custom-text {
  font-weight: bold;
  font-size: 1.9em;
  border: 1px solid #cfcfcf;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  color: #999;
  background: #fff;
  &--primary-accent {
    color: #2962ff;
  }
  &--yellow-accent {
    color: #f3d055;
  }
}

.vdatetime-popup {
  z-index: 20000 !important;
}

.row-images {
  width: 100%;
  max-height: 500px;
  overflow: auto;

  .vs-image--img {
    background-size: cover;
  }
}

.grecaptcha-badge {
  display: none !important;
}

.con-input-upload,
.con-img-upload {
  margin: 0 auto !important;
}
.con-input-upload,
.img-upload {
  margin: 15px auto !important;
  float: initial !important;
}

.upload-container {
  overflow: hidden;
  padding: 5px 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-rtl {
  direction: rtl;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  /* proof of concept: */
  width: 75%;
  display: inline-block;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}

.text-gray {
  color: gray;
}

// Vuesax Fix
.vs-con-table {
  padding: 5px;
}

.vs-sidebar-group.vs-sidebar-group-open h4 {
  font-size: 0.85em !important;
}

input,
input:before,
input:after {
  -webkit-user-select: initial !important;
  -khtml-user-select: initial !important;
  -moz-user-select: initial !important;
  -ms-user-select: initial !important;
  user-select: initial !important;
}
.mb-12 {
  margin-bottom: 3rem;
}

.flex {
  display: flex;
}
.flex-auto {
  flex: auto;
}

.items-center {
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

.hover-icon {
  border: 1px solid transparent;
  padding: 4px 2px 0 2px;
}
.hover-icon:hover {
  background: rgba(0, 47, 108, 0.4);
  cursor: pointer;
}

.absolute {
  position: absolute !important;
}
.relative {
  position: relative !important;
}
.bottom-0 {
  bottom: 0;
}

.font-semibold {
  font-weight: 700 !important;
}

.shadow-outline {
  box-shadow: 0 0 0 4px rgba(52, 144, 220, 0.85) !important;
}

.file-link {
  cursor: pointer;
  color: rgba(52, 144, 220, 0.85);
}

.vs-divider--text {
  font-size: 1.2rem !important;
}

/** Transitions
******************************************************************************/

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.btn-block {
  width: 100% !important;

  // Vertically space out multiple block buttons
  + .btn-block {
    margin-top: $btn-block-spacing-y;
  }
}
