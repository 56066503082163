






















































@import '~@/scss/vuexy/_variables.scss';

footer#main-footer {
  background: $primary;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #373a3c !important;
  text-align: center;

  .footer-header {
    font-size: 1rem;
    padding: 0.2rem 2rem;
    align-items: center;
    display: flex;
    min-height: $footer-height;
  }

  a.link {
    color: #fefefe;
    font-weight: 300;
    transition: all 0.3s ease;
    &:hover {
      color: #cdcdcd;
    }
  }
}
