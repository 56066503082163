// //////////////////////////////////////////
// Tour
// //////////////////////////////////////////

/*! rtl:begin:ignore */
.v-step {
  &[x-placement^=bottom] {
    .v-step__arrow {
      border-left-color: transparent !important;
      border-right-color: transparent !important;
    }
  }
  &[x-placement^=left] {
    .v-step__arrow {
      border-bottom-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
    }
  }
  &[x-placement^=top] {
    .v-step__arrow {
      border-bottom-color: transparent !important;
      border-right-color: transparent !important;
      border-left-color: transparent !important;
    }
  }
  &[x-placement^=right] {
    .v-step__arrow {
      border-bottom-color: transparent !important;
      border-top-color: transparent !important;
      border-left-color: transparent !important;
    }
  }
}



// //////////////////////////////////////////
// Carousel
// //////////////////////////////////////////


.swiper-container-rtl {
  .swiper-button-prev {
    right: 10px !important;
    left: auto !important;
  }
  .swiper-button-next {
    left: 10px !important;
    right: auto !important;
  }
}



// //////////////////////////////////////////
// video.js
// //////////////////////////////////////////

.vjs-fluid {
  padding-top: 41.66666666666667% !important;
}
/*! rtl:end:ignore */
