

















































@import '~@/scss/vuexy/_variables.scss';
#main-navbar {
  color: rgb(255, 255, 255);
  height: $navbar-height;
  top: 0;
  left: 0;
  .page-title {
    margin-left: 1rem;
    font-size: 1.2rem;
  }
  .vs-navbar--btn-responsive {
    display: none;
  }
  a.link {
    color: #fefefe;
    font-weight: 300;
    transition: all 0.3s ease;
    &:hover {
      color: #cdcdcd;
    }
  }
}
