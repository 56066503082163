.vx-card{
    width: 100%;
    background: #fff;
    border-radius: .5rem;
    display: block;
    box-shadow: 0px 4px 25px 0px rgba(0,0,0,.1);
    position: relative;
    transition: all .3s ease-in-out;

    &.no-shadow { box-shadow: none; }
    &.card-border { border: 1px solid #e4e4e4;  }

    .vx-card__header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 1.5rem 1.5rem 0;
        .vx-card__title {
            h4 + h6 {
                margin-top: .3rem;
            }

            h6 {
                font-weight: 400;
            }
        }
        .vx-card__actions {
            .vx-card__action-buttons {
                display: flex;

                & .feather-icon {
                    margin-left: .5rem;
                }
            }
            svg {
                transition: all .25s ease-out;
                height: 1rem;
                width: 1rem;
                cursor: pointer;
            }
            .rotate180 > svg{
                transform: rotate(180deg) !important;
            }
        }
    }

    img.card-img-top{
        border-top-left-radius: .5rem;
        border-top-right-radius: .5rem;
    }

    .vx-card__collapsible-content {
        transition: all .3s ease-in-out;

        &.vs-con-loading__container {
            overflow: unset;
        }

        &.collapsed {
            opacity: 0;
            transform: scale(0.99);
        }

        .card-overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 1.25rem;

            > * {
                position: relative;
                z-index: 1;
            }

            &::after{
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                right: 0;
                margin: auto;
                height: 100%;
                width: 100%;
                background: rgba(0,0,0,.60);
                z-index: 0;
            }
        }

        .vx-card__body {
            padding: 1.5rem;

            img + .vx-card__title > h4 { margin-top: 1.5rem }

        }
        img { display: block }
    }

    .vx-card__code-container {
        overflow: hidden;
        transition: transform 0.35s, opacity .15s, max-height .30s ease-out;

        &.collapsed {
            opacity: 0;
            transform: translateY(100%);
        }

        .code-content {
            margin: 1.5rem;

            pre[class^="language-"] {
                max-height: 350px;
                border-radius: .5rem;
                margin-bottom: 0;
            }
        }
    }

    .vx-card__footer {
        padding: 0 1.5rem 1.5rem;
    }
}
