code {
  font-family: $font-family-sans-serif;
  background: #eee;
  padding: 0.1rem 0.3rem;
  border-radius: 3px;
}

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}


.single-counter {
  display: inline-block;
  position: relative;
  width: 105px;
  padding: 18px 10px 10px;

  span {
    display: block;
    text-align: center;
  }

  .timer {
    font-size: 3rem;
  }
}

.chat-card-log {
  height: 240px;
}

// ======================================================================================
// Video.js
// ======================================================================================
.vjs-poster {
  background-size: cover !important;
  width: 100% !important;
}

// ======================================================================================
// Spinner
// ======================================================================================

// .spinner {
//   animation: spin 1.5s linear infinite;
// }

/*! rtl:begin:ignore */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
/*! rtl:end:ignore */

// ======================================================================================
// Feather - Font Icon
// ======================================================================================

i.feather {
  font-weight: 100;
}

.feather.feather-menu {
  z-index: 9;
}

// ======================================================================================
// Perfect Scrollbar
// ======================================================================================

.scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
}

.label-vertical-container {
  .label-vertical {
    writing-mode: vertical-lr;
  }
}
