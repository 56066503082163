









































































































































































































































.side-bar-router {
  padding: 10px;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  display: block;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: inherit;
  opacity: 0.8;
  font-size: 0.85rem;
  text-decoration: none;
  &:hover {
    color: rgba(var(--vs-primary), 1) !important;
    opacity: 1 !important;
  }
}

.active-route {
  color: rgba(var(--vs-primary), 1) !important;
  opacity: 1 !important;
  font-weight: bold;
}

.long-text {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.header-sidebar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  .header-desc {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    > button {
      margin-left: 10px;
    }
  }
}

.footer-sidebar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  > button {
    border: 0px solid rgba(0, 0, 0, 0) !important;
    border-left: 1px solid rgba(0, 0, 0, 0.07) !important;
    border-radius: 0px !important;
  }
}
.font-s-1 {
  font-size: 1rem;
}
